<template>

  <div>

    <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :user-id="userID"
        @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показ</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>строка</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
              />
              <b-button
                  variant="primary"
                  @click="addUser(0)"
              >
                <span class="text-nowrap">Добавить</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Записи не найдены"
          :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Статус показ -->
        <template #cell(show_in_landing)="data">
          <b-form-checkbox
              v-model="data.item.show_in_landing"
              class="custom-control-primary"
              @change="showInLanding(data.item.id)"
          >
          </b-form-checkbox>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.photo ? 'https://edu.startupchoikhona.tj/backend/'+data.item.photo : ''"
                  :text="avatarText(data.item.name)"
                  :variant="`light-success`"
              />
            </template>
            <span
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </span>
            <small class="text-muted">{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
          >
            {{ statusTranslate[data.item.status] }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span @click="addUser(data.item.id)">
            <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
          </span>
          <span @click="deleteUser(data.item.id)">
            <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
          </span>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} строк</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    UserListAddNew,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  created() {},
  methods: {
    addUser(idUser) {
      this.userID = idUser
      this.isAddNewUserSidebarActive = true
    },
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)
    const userID = ref(0)

    const statusTranslate = {
      0: 'Неактивный',
      1: 'Активный',
      2: 'В ожидание',
    }

    const statusOptions = [
      {
        label: 'В ожидание',
        value: '2',
      },
      {
        label: 'Активный',
        value: '1',
      },
      {
        label: 'Неактивный',
        value: '0',
      },
    ]

    const {
      fetchUsers,
      deleteUser,
      showInLanding,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserStatusVariant,

      // Extra Filters
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      deleteUser,
      showInLanding,
      fetchUsers,
      userID,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserStatusVariant,
      statusOptions,
      statusTranslate,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

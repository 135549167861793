<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :no-close-on-backdrop="true"
    @shown="sideBarShown"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userId > 0 ? 'Редактировать пользователь' : 'Добавить нового пользователя' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- ФИО -->
          <validation-provider
            #default="validationContext"
            name="ФИО"
            rules="required"
          >
            <b-form-group
              label="ФИО"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Номер телефон -->
          <validation-provider
            #default="validationContext"
            name="номер телефон"
            rules="regex:^([0-9\s\-\+\(\)]*)$"
          >
            <b-form-group
              label="Номер телефон"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.phone"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Имя пользователя -->
          <validation-provider
            #default="validationContext"
            name="Имя пользователя"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Имя пользователя"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Эл. почта -->
          <validation-provider
            #default="validationContext"
            name="Эл. почта"
            rules="required|email"
          >
            <b-form-group
              label="Эл. почта"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Организация -->
          <validation-provider
            #default="validationContext"
            name="Организация"
            rules="required"
          >
            <b-form-group
              label="Организация"
              label-for="organization"
            >
              <b-form-input
                id="organization"
                v-model="userData.organization"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            v-if="userId === 0"
            #default="{ errors }"
            name="Пароль"
            vid="password"
            rules="required|min:8"
          >
            <b-form-group
              label-for="register-password"
              label="Пароль"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="password"
                  placeholder="············"
                  autocomplete="off"
                  trim
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            v-else
            label-for="register-password"
            label="Пароль"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                class="form-control-merge"
                :type="passwordFieldType"
                name="password"
                placeholder="············"
                autocomplete="off"
                trim
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!-- Подтверждение пароль -->
          <validation-provider
            v-if="userId === 0"
            #default="{ errors }"
            name="Подтверждение пароль"
            vid="Подтверждение пароль"
            rules="required|confirmed:password"
          >
            <b-form-group
              label-for="confirm-password"
              label="Подтверждение пароль"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="confirm-password"
                  v-model="userData.password_confirmation"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                  trim
                />
              </b-input-group>
              <b-form-invalid-feedback>
                {{ errors[0] }} sdrfvsrf
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            v-else
            label-for="confirm-password"
            label="Подтверждение пароль"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="confirm-password"
                v-model="userData.password_confirmation"
                class="form-control-merge"
                :type="passwordFieldType"
                autocomplete="off"
                trim
              />
            </b-input-group>
          </b-form-group>

          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :src="userData.avatar"
                :text="avatarText(userData.name)"
                :variant="`light-primary`"
                size="90px"
                rounded
                @click="$refs.refInputEl.click()"
              />
            </template>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="$refs.refInputEl.click()"
              >
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  accept="image/*"
                  @input="inputImageRenderer"
                >
                <feather-icon
                  icon="DownloadIcon"
                  class="d-inline"
                />
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1"
              >
                <feather-icon
                  icon="TrashIcon"
                  class=""
                />
              </b-button>
            </div>
          </b-media>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BMedia,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, regex,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import http from '@axios'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    sideBarShown() {
      this.getUser()
    },
    getUser() {
      if (this.userId > 0) {
        this.$http
          .post(`https://edu.startupchoikhona.tj/backend/api/user/${this.userId}/view`, {
          })
          .then(response => {
            const {
              data,
            } = response.data
            this.userData = {
              name: data.name,
              phone: data.phone,
              username: data.username,
              email: data.email,
              role_id: 3,
              organization: data.organization,
              password: '',
              password_confirmation: '',
              avatar: null,
              photo: null,
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение менторы',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.userData = {
          name: '',
          phone: '',
          username: '',
          email: '',
          role_id: 3,
          organization: '',
          password: '',
          password_confirmation: '',
          avatar: null,
          photo: null,
        }
      }
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      phone: '',
      username: '',
      email: '',
      role_id: 3,
      organization: '',
      password: '',
      password_confirmation: '',
      avatar: null,
      photo: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          if (data[key] && key !== 'avatar') {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
          }
        })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      userData.value.avatar = base64
      userData.value.photo = refInputEl.value.files[0]
    })

    const onSubmit = () => {
      const formData = new FormData()

      buildFormData(formData, userData.value)
      if (props.userId > 0) {
        http.post(`https://edu.startupchoikhona.tj/backend/api/user/update/${props.userId}`, formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
          })
      } else {
        http.post('https://edu.startupchoikhona.tj/backend/api/user/create', formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    localize('ru', ru)
    return {
      avatarText,
      refInputEl,
      previewEl,
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
